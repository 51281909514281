body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Large Button */

.large-button-box {
  position: relative;
  overflow: hidden;
}

.gradient-button {
  padding: 10px 50px;
  display: block;
  margin: 0px auto;
  overflow: hidden;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  border: 0px;
  border-radius: 20px;
  background-color: var(--third-color);
  /*background-image: linear-gradient(225deg, #fff 0%, #000 50%, #fff 100%);*/
  /* box-shadow: -5px 5px 15px 0px rgba(77, 77, 77, 0.2), 5px -5px 15px 0px rgba(77, 77, 77, 0.2); */
}

.gradient-button:hover {
  transform: translate(0, -1px);
  background-color: var(--first-color);
  /* box-shadow: 10px -10px 25px 0px rgba(39, 200, 255, .25), -10px 10px 25px 0px rgba(39, 200, 255, .25); */
  color: white;
}

.gradient-button:focus {
  outline: none !important;
}
