* {
  box-sizing: 0;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  /* DISABLE OVER SCROLL */
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* DISABLE OVER SCROLL */
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  /* DISABLE HIGHLIGHTING */
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --first-color: #2a2a2a;
  --second-color: #19a9e5;
  --third-color: #ffd42d;
  --fourth-color: #000000;
  --fifth-color: #ffffff;
  --sixth-color: #999999;
}

.App {
  margin: 0 auto;
  width: 1200px;
}

@font-face {
  font-family: Montserrat-Light;
  src: url('./assets/fonts/Montserrat-Light.ttf') format('trueType');
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('trueType');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('trueType');
}

@font-face {
  font-family: WebSymbols-Regular;
  src: url('./assets/fonts/WebSymbols-Regular.otf') format('openType');
}

/* Navbar */

body {
  font-family: 'Montserrat-Light';
}

header {
  position: fixed;
  z-index: 3;
  top: 0;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  background-color: var(--fifth-color);
}

header #wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  background-color: var(--fifth-color);
}

header #wrapper #logo {
  display: none;
}

header #wrapper #logo img {
  width: 32px;
}

header #wrapper #title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

header #wrapper #title img {
  margin: 0 10px;
  width: 32px;
}

header #wrapper #title a {
  vertical-align: 6px;
  text-decoration: none;
  font-family: 'Montserrat-Medium';
  font-size: larger;
  color: var(--first-color);
}

header #wrapper #menu-max {
  padding-right: 30px;
  /* margin: 0 auto; */
  /* float: right; */
  /* width: 55%; */
  height: 100%;
  /* margin-top: 14px; */
  text-align: right;
}

header #wrapper #menu-max ul {
  margin: 0 auto;
  height: 100%;
}

header #wrapper #menu-max ul li {
  display: inline-block;
  height: 100%;
  padding: 0 12px 0 12px;
  font-family: 'Montserrat-Medium';
  font-size: larger;
  cursor: pointer;
}

header #wrapper #menu-max ul li a {
  text-decoration: none;
  color: var(--fourth-color);
  margin: 0px;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 50px;
}

header #wrapper #menu-max ul li:hover a {
  color: var(--second-color);
}

header #wrapper #menu-min {
  display: none;
}

nav#menu-min-options {
  float: left;
}

#shadow {
  position: fixed;
  z-index: 1;
  top: 0;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 0 5px black;
}

#hidden {
  position: absolute;
  width: 100%;
  height: 8px;
  background: white;
  top: 50px;
  z-index: 2;
}

.hidden {
  margin-top: -80px;
  transition: 0.4s;
}

.show {
  margin-top: 50px;
  transition: 0.4s;
}

#hamburger-menu {
  display: none;
}

@media only screen and (max-width: 800px) {
  header #wrapper {
    width: 100%;
  }
  header #wrapper #logo {
    display: flex;
    padding: 13px;
  }
  header #wrapper #title {
    text-align: center;
  }
  header #wrapper #title img {
    display: none;
  }
  header #wrapper #menu-max {
    display: none;
  }
  header #wrapper #menu-min {
    display: block;
  }
  header #wrapper #menu-min .MuiSvgIcon-root {
    margin-top: 8px;
    width: 80%;
    height: 33px;
  }
  nav#menu-min-options ul li {
    height: 30px;
    /* width: 76%; */
    background: var(--fifth-color);
    font-family: 'Montserrat-Medium';
    color: var(--fourth-color);
    /* text-shadow: 0px 0px 0.7px #333; */
    text-align: center;
    padding-top: 8px;
    margin: 0 auto;
    /* margin-bottom: 1px; */
    list-style: none;
    /* border-top: 1px solid;*/
  }
  nav#menu-min-options ul li a {
    text-decoration: none;
    color: black;
    margin: 0px;
    display: block;
    width: 100%;
    height: 100%;
  }
  nav#menu-min-options ul li:first-child {
    border-top: 1px solid #bbb;
  }
  nav#menu-min-options {
    clear: both;
    float: left;
    width: 100%;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 45%);
    background: var(--fifth-color);
    position: fixed;
    z-index: 2;
    top: 0;
  }

  #hamburger-menu {
    display: flex;
    width: 24px;
    height: 18px;
    margin: 15px 15px;
    position: relative;
    cursor: pointer;
  }

  #hamburger-menu span {
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    opacity: 1;
    background-color: #000000;
    border-radius: 2px;
    pointer-events: none;
    transform: rotate(0deg);
    transition: 0.2s ease;
  }

  #hamburger-menu span:nth-child(1) {
    top: 0px;
  }

  #hamburger-menu span:nth-child(2),
  #hamburger-menu span:nth-child(3) {
    top: 50%;
  }

  #hamburger-menu span:nth-child(4) {
    top: 100%;
  }

  #hamburger-menu.open span:nth-child(1),
  #hamburger-menu.open span:nth-child(4) {
    top: 50%;
    width: 0%;
    left: 50%;
  }

  #hamburger-menu.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  #hamburger-menu.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
}

/* Home */

section#home {
  height: 110vh;
  width: 100%;
  overflow: hidden;
}

section#home #wrapper {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 80%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 90vh;
  margin: 10%; */
}

section#home #wrapper h1 {
  margin-bottom: 30px;
  font-size: 48px;
  text-align: left;
}

section#home #wrapper #description {
  font-family: 'Montserrat-Light';
  font-size: xx-large;
  letter-spacing: 0px;
  word-spacing: 0px;
  /*line-height: 28px;*/
  text-align: justify;
}

section#home #wrapper #description a {
  font-family: 'Montserrat-Bold';
  text-decoration: none;
  color: var(--second-color);
}

section#home #water-mark img {
  position: fixed;
  top: 10%;
  left: 5%;
  z-index: 0;
  filter: opacity(0.1);
}

section#home #spinners {
  position: absolute;
  z-index: 0;
  width: 50%;
  height: 100%;
  bottom: 0;
  top: 65%;
  left: 45%;
  margin-right: 0;
  margin-left: 0;
  overflow: hidden;
  padding-top: 5%;
  padding-left: 5%;
}
section#home #spinners img {
  position: absolute;
  animation: spin 60s infinite;
}

@media only screen and (max-width: 600px) {
  section#home #wrapper h1 {
    font-size: xx-large;
    text-align: center;
  }

  section#home #wrapper #description {
    font-size: x-large;
  }

  section#home #water-mark img {
    position: fixed;
    top: 10%;
    left: -20%;
  }

  section#home #spinners {
    width: 75%;
    top: 80%;
    left: 20%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* About */

section#aboutMe {
  height: 100%;
}

section#aboutMe::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
  background-color: var(--first-color);
  box-shadow: 0px 0px 10px #0000008f;
}

section#aboutMe #wrapper {
  color: #fff;
  padding: 100px 10% 200px 10%;
}

section#aboutMe #wrapper h1 {
  font-size: 48px;
  text-align: center;
  margin-bottom: 15px;
}

section#aboutMe #wrapper hr {
  color: var(--third-color);
  border: 1px solid;
  margin: 0 auto;
  margin-bottom: 5%;
  width: 50%;
}

section#aboutMe img {
  width: 20%;
  display: block;
  margin: 0 auto 50px;
  border-radius: 50%;
}

section#aboutMe #description {
  text-align: justify;
  font-family: 'Montserrat-Light';
  font-size: x-large;
  letter-spacing: 0.8px;
  word-spacing: 0px;
  line-height: 36px;
}

section#aboutMe #wrapper h2 {
  font-size: xx-large;
  margin-top: 15px;
  margin-bottom: 30px;
}

section#aboutMe #wrapper #skills {
  line-height: 42px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
}

section#aboutMe #wrapper #skills span {
  /* float: left;*/
  /* width: 33.33%; */
  font-size: x-large;
}

section#aboutMe #wrapper #skills span svg {
  color: var(--third-color);
  width: 20px;
}

@media only screen and (max-width: 600px) {
  section#aboutMe #wrapper {
    padding: 100px 10%;
  }
  section#aboutMe img {
    width: 35%;
  }
  section#aboutMe #wrapper h1 {
    font-size: xx-large;
  }
  section#aboutMe #wrapper h2 {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  section#aboutMe #wrapper hr {
    width: 100%;
  }
  section#aboutMe #description {
    font-size: large;
    line-height: 28px;
  }
  section#aboutMe #wrapper #skills {
    line-height: 30px;
    height: 100%;
  }
  section#aboutMe #wrapper #skills span {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  section#aboutMe #wrapper #skills span {
    font-size: larger;
  }
}

/* Projects */

section#projects {
  min-height: 180vh;
  overflow: hidden;
}

section#projects::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
}

section#projects #wrapper {
  color: black;
  padding: 100px 10% 180px 10%;
  /* margin-bottom: 300px; */
}

section#projects #wrapper h1 {
  font-size: 48px;
  text-align: center;
  margin-bottom: 15px;
}

section#projects #wrapper hr {
  background-color: var(--third-color);
  border: 1px solid var(--third-color);
  color: var(--third-color);
  margin: 0 auto;
  margin-bottom: 5%;
  width: 50%;
}

section#projects #wrapper h2 {
  font-size: 36px;
  text-align: center;
  margin: 5% 0;
}

section#projects #wrapper .flex-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

section#projects #wrapper .flex-box-reverse {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.project-top {
  width: 48%;
  /* margin: 0 2% 0 0%; */
}

.project-top img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.project-bottom {
  width: 48%;
  /* margin: 0 0% 0 2%; */
  overflow: hidden;
  margin-bottom: 3%;
}

.project-bottom .description {
  text-align: justify;
  font-size: x-large;
  margin-bottom: 12px;
  line-height: 32px;
}

.project-bottom .technologies {
  text-align: left;
  font-size: x-large;
  font-weight: 700;
  margin-bottom: 12px;
}

.project-bottom .button-box .large-button-box {
  padding: 30px;
}

.project-bottom .link {
  font-family: Montserrat-Medium;
  font-size: larger;
  color: var(--fourth-color);
  text-align: center;
  text-decoration: none;
  margin: 0 auto;
  display: block;
  background-color: var(--third-color);
  height: 40px;
  width: 50%;
  line-height: 200%;
}

.project-bottom .link:hover {
  background: black;
  color: white;
}

.project-bottom .technologies span {
  margin-left: 5px;
  vertical-align: text-top;
}

.project-bottom .technologies svg {
  margin: 0 0px 0 7px;
  font-size: x-large;
  color: var(--second-color);
}

section#projects #wrapper .separator-line {
  border: 0px;
}

.project-top-inverse {
  width: 48%;
  /* margin: 0 0% 0 2%; */
  overflow: hidden;
  /* margin-bottom: 3%; */
}

.project-bottom-inverse {
  width: 48%;
  /* margin: 0 2% 0 0%; */
}

@media only screen and (max-width: 1800px) {
  .project-bottom .description,
  .project-bottom .technologies {
    font-size: larger;
  }
  section#projects #wrapper {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 800px) {
  section#projects {
    min-height: 230vh;
    overflow: hidden;
  }
  section#projects #wrapper {
    padding: 20% 10% 0% 10%;
  }
  section#projects #wrapper h1 {
    font-size: xx-large;
  }
  section#projects #wrapper hr {
    width: 100%;
  }
  section#projects #wrapper h2 {
    font-size: 28px;
    font-weight: bolder;
  }
  section#projects #wrapper .flex-box {
    display: block;
  }
  section#projects #wrapper .flex-box-reverse {
    display: block;
  }
  section#projects #wrapper .separator-line {
    background-color: var(--sixth-color);
    border: 1px solid var(--sixth-color);
    margin: 0 auto;
    margin-bottom: 15%;
    width: 100%;
  }
  .project-top {
    width: 100%;
  }
  .project-top img {
    width: 100%;
  }
  .project-bottom {
    width: 100%;
  }
  .project-bottom .description {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .project-bottom .technologies {
    text-align: center;
  }
  .project-bottom .description,
  .project-bottom .technologies {
    font-size: large;
  }
  .project-bottom .description,
  .project-bottom .technologies span {
    padding-top: 6px;
    display: block;
  }
  .project-bottom .button-box .large-button-box {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .project-bottom .link {
    margin: 0 auto;
  }
}

/* Contact */

/* section#contact { */
/*height: 130vh;*/
/* } */

section#contact::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
  background-color: var(--first-color);
  box-shadow: 0px 0px 10px #0000008f;
  /*-webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);*/
}

section#contact::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 90%;
  top: 30%;
  margin-bottom: 0;
  background-color: var(--first-color);
}

section#contact #wrapper {
  color: white;
  padding: 14% 12% 4% 12%;
}

section#contact #wrapper h1 {
  font-size: 48px;
  text-align: center;
  margin-bottom: 15px;
}

section#contact #wrapper hr {
  margin: 0 auto;
  margin-bottom: 5%;
  border: 1px solid var(--third-color);
  width: 50%;
}

section#contact #wrapper #flex-box {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: baseline;
}

section#contact #wrapper #details {
  width: 100%;
  margin-left: 5%;
  line-height: 200%;
}

section#contact #wrapper #details p {
  font-size: xx-large;
  font-family: Montserrat-Medium;
  margin-left: 10%;
  margin-bottom: 40px;
}

section#contact #wrapper #details div {
  display: block;
  margin: 5% 2%;
  font-size: larger;
}

section#contact #wrapper #details div span:hover {
  color: var(--second-color);
  cursor: pointer;
}

section#contact #wrapper #details div svg {
  font-size: larger;
  margin-right: 15px;
}

section#contact #wrapper form {
  overflow: hidden;
  width: 100%;
  padding: 20px 0px 40px;
}

section#contact #wrapper form input[type='text'],
section#contact #wrapper form input[type='email'],
section#contact #wrapper form textarea {
  padding-left: 10px;
  outline: none;
}

section#contact #wrapper form input,
section#contact #wrapper form textarea {
  display: block;
  font-family: Montserrat-Light;
  font-size: small;
  width: 100%;
  max-width: -webkit-fill-available !important;
  height: 35px;
  /*Apple styles*/
  margin-bottom: 4%;
  border-radius: 20px !important;
}

section#contact #wrapper form input[type='text']:focus,
section#contact #wrapper form input[type='email']:focus,
section#contact #wrapper form textarea:focus {
  /* outline-style: none; */
  /* box-shadow: 5px -5px 15px 0px rgba(39, 200, 255, .25), -5px 5px 15px 0px rgba(39, 200, 255, .25); */
  border: 2px solid var(--second-color);
}

section#contact #wrapper form textarea {
  width: 100% !important;
  height: 100px !important;
  padding-top: 6px;
  border-style: inset !important;
  border-width: 2px !important;
  clear: both;
  /*Apple styles*/
  border-radius: 20px !important;
}

section#contact #wrapper form input[type='submit'] {
  background: var(--second-color);
  color: var(--fifth-color);
  font-family: Montserrat-Medium;
  font-size: larger;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 30px;
  height: 40px;
  width: 50%;
  border: 0;
  /*Apple styles*/
  border-radius: 20px !important;
  -webkit-appearance: none !important;
}

section#contact #wrapper form input[type='submit']:hover {
  /* transform: translate(0, -1px); */
  background-color: var(--fifth-color);
  /* box-shadow: 10px -10px 25px 0px rgba(39, 200, 255, .25), -10px 10px 25px 0px rgba(39, 200, 255, .25); */
  color: var(--first-color);
}

/* section#contact #wrapper form input[type="submit"]:focus {
  outline: none !important;
  background: var(--fifth-color);
  color: var(--first-color);
} */

section#contact #wrapper form label {
  position: absolute;
  display: none;
  text-align: center;
  color: var(--fifth-color);
  font-size: small;
  margin: 0 auto;
  margin-top: -14px;
  margin-bottom: 15px;
}

.invalid {
  border: 2px solid red;
}

.visible {
  display: block !important;
}

@media only screen and (max-width: 800px) {
  section#contact #wrapper {
    padding: 25% 10% 0 10%;
  }

  section#contact #wrapper h1 {
    font-size: xx-large;
  }

  section#contact #wrapper hr {
    width: 100%;
    margin-bottom: 10%;
  }

  section#contact #wrapper #flex-box {
    display: block;
  }

  section#contact #wrapper #details {
    /* float: none; */
    width: 100%;
    line-height: 0;
  }

  section#contact #wrapper #details p {
    display: none;
    margin: 0% 0;
  }

  section#contact #wrapper #details div {
    font-size: medium;
  }

  section#contact #wrapper #details div svg {
    margin-right: 6px;
    margin-left: 3px;
  }

  section#contact #wrapper form label {
    margin-top: -8px;
  }
}

@media only screen and (max-width: 1200px) {
  section#contact #wrapper #details div {
    display: block;
    margin: 5% 2%;
    font-size: medium;
  }
}

/* Footer */

footer {
  position: absolute;
  line-height: 300%;
  text-align: center;
  width: 100%;
  background: var(--fourth-color);
  box-shadow: 0 0 5px var(--fourth-color);
}

footer #description {
  text-align: center;
}

footer #description span {
  margin: 0 auto;
  margin: 40px 1%;
  text-align: center;
  display: inline-block;
  width: 25%;
}

footer #description span svg {
  font-size: 48px;
  text-decoration: none;
  color: white;
  background-color: transparent;
}

footer #description span svg:hover {
  color: var(--second-color);
}

footer #copy {
  border-top: 1px solid var(--first-color);
  font-family: 'Montserrat-Medium';
  font-size: larger;
  color: white;
}

@media only screen and (max-width: 600px) {
  footer #description span svg {
    font-size: xx-large;
  }
}
